import authService from '../../auth/authService'
import Vue from 'vue'

const userDefaults = {
    uid: 0,
    displayName: '',
    about: '',
    status: 'online',
    userRole: '/'
}

export default {
    namespaced: true,
    state: {
        AppActiveUser: userDefaults,
        loggedIn: false,
        userData: null,
        siteKey: '6LeMvoUbAAAAAA8vsXyiMZDeeLVQcNLvno8j-Gd_',
        userEmailConfirmed: false

    },
    getters: {
        emailConfirmed(state) {

            if (state.loggedIn === false) {
                return true
            }

            return state.userEmailConfirmed
        },
        loggedIn(state) {
            return state.loggedIn
        },
        userData(state) {
            return state.userData
        }
    },
    mutations: {
        SET_LOGGED_IN(state) {
            state.loggedIn = true
        },
        UPDATE_USER_DATA(state, data) {
            state.userData = data
        },
        SET_LOGIN_STATUS(state, status) {
            state.loggedIn = status
        },
        SET_EMAIL_CONFIRMED(state, data) {
            state.userEmailConfirmed = data.confirmed
        }
    },
    actions: {
        login({ commit }, payload) {
            authService.setLoginParameters(payload)
            commit('SET_LOGGED_IN')
        },
        setUserData({ commit }, payload) {
            commit('UPDATE_USER_DATA', payload)
            localStorage.setItem('userData', JSON.stringify(payload))
        },

        updateUserData({ commit }, data) {
            commit('UPDATE_USER_DATA', data)
        },
        setLoginStatus({ commit }, data) {
            commit('SET_LOGIN_STATUS', data)
        },
        setEmailConfirmed({ commit }, data) {
            commit('SET_EMAIL_CONFIRMED', data)
        },
        async setLoggedInFromMobileApp({ dispatch }, query) {
            try {
                const userData = JSON.parse(Buffer.from(query.user_data, 'base64').toString())

                document.cookie = `JWT_APP=${userData.token};path=/;`
                document.cookie = `JWT_IIPORTAL_APP=${userData.iiportal_token};path=/;`

                dispatch('login', userData.token)
                dispatch('setUserData', userData)
            } catch (error) {
                Vue.prototype.$printError('Prišlo je do napake pri prijavi')
            }
        }
    }
}
