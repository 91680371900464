import Vue from 'vue'
import VueRouter from 'vue-router'
import authService from '../auth/authService.js'
import store from '../store/index.js'
import realEstateRoutes from './RealEstate.js'
import myPortalRoutes from './MyPortal.js'
import myProfileRoutes from '@/router/MyProfile'
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/portal',
            name: 'home',
            component: () => import('@/views/Pages/HomeSecond/HomeSecond.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/kosarica',
            name: 'shopping-card',
            component: () => import('@/views/Pages/ShoppingCard/ShoppingCard.vue'),
            meta: {
                // requiresAuth: true
            }
        },
        {
            path: '',
            component: () => import('@/views/Pages/RealEstate/RealEstateList'),
            children: realEstateRoutes
        },
        {
            path: '/nepremicnine/:real_estate_id',
            name: 'real-estate',
            component: () => import('@/views/Pages/RealEstate/RealEstate'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Nepremičnine',
                        active: false,
                        to: '/nepremicnine'
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/ugodnosti/akcije-in-popusti',
            name: 'promotions-and-discounts',
            component: () => import('@/views/Pages/WebShop/PromotionsAndDiscounts'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Ugodnosti',
                        active: false
                    },
                    {
                        text: 'Akcije in popusti',
                        active: true
                    }
                ],
                requiresAuth: true
            }
        },
        {
            path: '/izdelki',
            name: 'web-shop-items-list',
            component: () => import('@/views/Pages/WebShop/AllItemsList'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Izdelki',
                        active: true
                    }
                ],
                requiresAuth: true
            }
        },
        {
            path: '/pogoji-poslovanja/:upravnik',
            name: 'legal-notice',
            component: () => import('@/views/Pages/BusinessConditions/BusinessConditions'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Pogoji poslovanja',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/pogoji-poslovanja',
            name: 'legal-notice',
            component: () => import('@/views/Pages/BusinessConditions/BusinessConditions'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Pogoji poslovanja',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/pogosta-vprasanja',
            name: 'faq',
            component: () => import('@/views/Pages/FAQ/FAQ'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/vase-mnenje',
            name: 'your-opinion',
            component: () => import('@/views/Pages/Footer/YourOpinion'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/izdelki/:category_id',
            name: 'web-shop-category-items-list',
            component: () => import('@/views/Pages/WebShop/CategoryItemsList'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Izdelki',
                        active: false,
                        to: '/izdelki'
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: true
                    }
                ],
                requiresAuth: true
            }
        },
        {
            path: '/izdelek/:item_id',
            name: 'web-shop-item',
            component: () => import('@/views/Pages/WebShop/ItemPage'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Izdelki',
                        active: false,
                        to: '/izdelki'
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: false
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: true
                    }
                ],
                requiresAuth: true
            }
        },
        {
            path: '/placilo-s-kartico/:order_id',
            name: 'pay-with-card',
            component: () => import('@/views/Pages/ShoppingCard/Stripe.vue'),
            meta: {
                layout: 'full',
                requiresAuth: true
            }
        },
        {
            path: '/success-pay',
            name: 'success-pay',
            component: () => import('@/views/Pages/ShoppingCard/SuccessPay.vue'),
            meta: {
                layout: 'full',
                requiresAuth: true
            }
        },
        {
            path: '/novice',
            name: 'news-list',
            component: () => import('@/views/Pages/News/NewsList'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Novice',
                        active: true,
                        to: '/novice'
                    }
                ],
                requiresAuth: false
            }


        },
        {
            path: '/novice/:news_id',
            name: 'news',
            component: () => import('@/views/Pages/News/News'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Novice',
                        active: false,
                        to: '/novice'
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/storitve',
            name: 'service-list',
            component: () => import('@/views/Pages/Service/ServiceList'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Storitve',
                        active: true
                    }
                ],
                requiresAuth: true
            }
        },
        {
            path: '/storitev/:service_id',
            name: 'service',
            component: () => import('@/views/Pages/Service/Service'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Storitve',
                        active: false,
                        to: '/storitve'
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: true
                    }
                ],
                requiresAuth: true
            }
        },
        {
            path: '/dodaj-oglas',
            name: 'add-ad',
            component: () => import('@/views/Pages/C2C/AddClassifiedAd'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/dodaj-oglas/predogled',
            name: 'ad-preview',
            component: () => import('@/views/Pages/C2C/ClassifiedAdPreview'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/oglasi',
            name: 'ad-list',
            component: () => import('@/views/Pages/C2C/ClassifiedAdList'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Trznica',
                        active: true,
                        to: '/oglasi'
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/moji-oglasi/:ad_id',
            name: 'my-single-ad',
            component: () => import('@/views/Pages/C2C/MyClassifiedAdSingle'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Moji oglasi',
                        active: false,
                        to: '/moji-oglasi'
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: true
                    }
                ],
                requiresAuth: true
            }
        },
        {
            path: '/moji-oglasi',
            name: 'my-ads',
            component: () => import('@/views/Pages/C2C/MyClassifiedAdList'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Moji oglasi',
                        active: true,
                        to: '/moji-oglasi'
                    }
                ],
                requiresAuth: true
            }
        },
        {
            path: '/oglasi/:ad_id',
            name: 'ad',
            component: () => import('@/views/Pages/C2C/ClassifiedAd'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Tržnica',
                        active: false,
                        to: '/oglasi'
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: false
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/soseska/:real_estate_id',
            name: 'neighborhood',
            component: () => import('@/views/Pages/Neighborhoods/Neighborhood.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Soseska',
                        active: true,
                        to: '/soseska'
                    },
                    {
                        isCustomBreadcrumb: true,
                        text: '',
                        active: false
                    }
                ],
                requiresAuth: false
            }
        },
        //MOJ PROFIL
        {
            path: '/my-profile',
            name: 'my-profile',
            component: () => import('@/views/Pages/MyProfile/ProfileIndex.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Moj uporabniški račun',
                        to: '/my-profile/my-dashboard',
                        active: true
                    }
                ],
                requiresAuth: true
            },
            children: myProfileRoutes
        },
        {
            path: '/kontakti',
            name: 'contacts',
            component: () => import('@/views/Pages/Footer/Contacts'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Kontakti',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/piskotki',
            name: 'cookies',
            component: () => import('@/views/Pages/Footer/Cookies'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Piškotki',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/o-nas',
            name: 'about-us',
            component: () => import('@/views/Pages/Footer/AboutUs'),
            meta: {
                breadcrumb: [
                    {
                        text: 'O nas',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/tocke-zvestobe',
            name: 'loyalty-points',
            component: () => import('@/views/Pages/Footer/LoyaltyPoints'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Točke zvestobe',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/potek-nakupa',
            name: 'purchase-steps',
            component: () => import('@/views/Pages/Footer/PurchaseSteps'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Potek nakupa',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/pogoji-varovanja-osebnih-podatkov',
            name: 'conditions',
            component: () => import('@/views/Pages/Footer/Conditions'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Pogoji varovanja osebnih podatkov',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/nagrajevaje',
            name: 'awarding',
            component: () => import('@/views/Pages/Footer/Awarding.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Nagrajevaje',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        {
            path: '/nagrajevaje-pogoji',
            name: 'awarding-terms',
            component: () => import('@/views/Pages/Footer/AwardingTerms.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Nagrajevaje',
                        active: true
                    }
                ],
                requiresAuth: false
            }
        },
        //eUPRAVNIK
        {
            path: '',
            name: 'upravnik-home',
            component: () => import('@/views/Pages/MyPortal/MyPortal.vue'),
            meta: {
                requiresAuth: true
            },
            children: myPortalRoutes
        },

        {
            path: '/prijava',
            name: 'login',
            component: () => import('@/views/Pages/LoginRegister/Login.vue'),
            props: { type: 0 },
            meta: {
                //layout: 'full'
            }
        },
        {
            path: '/login_as_user/:user_id',
            name: 'login-as-user',
            component: () => import('@/views/Pages/LoginRegister/LoginAsUser'),
            meta: {
                //requiresAuth: true
            }
        },
        {
            path: '/pozabljeno-geslo',
            name: 'forgoten-password',
            component: () => import('@/views/Pages/LoginRegister/components/ForgottenPasswordComponent.vue'),
            props: { type: 0 },
            meta: {
                //layout: 'full'
            }
        },
        {
            path: '/ponastavi-geslo/:password_id',
            name: 'reset-password',
            component: () => import('@/views/Pages/LoginRegister/components/ResetPasswordComponent.vue'),
            props: { type: 0 },
            meta: {
                //layout: 'full'
            }
        },
        {
            path: '/preklici-ponastavitev-gesla/:password_id',
            name: 'cancel-reset-password',
            component: () => import('@/views/Pages/LoginRegister/components/ResetPasswordCancelComponent.vue'),
            props: { type: 0 },
            meta: {
                //layout: 'full'
            }
        },
        {
            path: '/registracija',
            name: 'register',
            component: () => import('@/views/Pages/LoginRegister/Login.vue'),
            props: { type: 1 },
            meta: {
                //layout: 'full'
            }
        },
        {
            path: '/aktivacija-racuna/:register_id',
            name: 'confirm-register',
            component: () => import('@/views/Pages/LoginRegister/components/ConfirmRegistration.vue'),
            props: { type: 1 },
            meta: {
                //layout: 'full'
            }
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full'
            }
        },
        {
            path: '*',
            redirect: 'error-404'
        }
    ]
})

router.beforeEach(async(to, from, next) => {
    let finalNext = null

    if (to.query && to.query.user_data) {
        await store.dispatch('user/setLoggedInFromMobileApp', to.query)
        await store.dispatch('shoppingCart/syncOnLogin')
        finalNext = {
            ...to,
            query: null
        }
    }

    if (to.path === '/real-estate-list-out') {
        window.location.href = ' https://nepremicnine.esoseska.si/'
        if (finalNext) next(finalNext)
        else next()
    }

    const rootEle = document.getElementById('app')
    rootEle.scroll({
        top: 0,
        behavior: 'smooth'
    })

    // pridobi loggedIn spremenljivko iz localStorage, ki jo ob loginu nastavimo na true
    const loggedIn = localStorage.getItem('loggedIn')
    if (loggedIn) {
        // ob reloadu strani (Ctrl + R) se vrednosti iz store (Vuex Store) pobrišejo
        // zato jih pridobimo iz localStorage in jih shranimo
        store.state.user.loggedIn = true
        const userData = localStorage.getItem('userData')
        if (userData) {
            store.state.user.userData = JSON.parse(userData)
        }
    }

    const cart = localStorage.getItem('cart')
    if (cart) {
        store.dispatch('shoppingCart/setCart', JSON.parse(cart))
    }

    // če stran, na katero želimo iti vsebuje meta podatek requiresAuth
    // in iz authService zvemo, da user ni prijavljen, ga pošljemo na login
    if (to.meta.requiresAuth && !authService.isAuthenticated()) {
        return next('/prijava')
    }

    // če meta vsebuje array roles (npr ['user', 'superuser'] ali ['admin'])
    // pogledamo v vuex store, če ima prijavljen uporabnik ta role
    if (to.meta.roles) {
        if (to.meta.roles.includes(store.state.user.userData.type)) {
            if (finalNext) return next(finalNext) // z next() spustimo dalje
            else return next()
        }
        return next('/prijava')
    }
    if (finalNext) next(finalNext)
    else next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
