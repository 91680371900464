import jsonwebtoken from 'jsonwebtoken'
import store from '../store/index.js'

const loggedIn = 'loggedIn'
const tokenExpiryKey = 'tokenExpiry'
const jwtToken = 'accessToken'

const authService = {

  logOut(deleteCart = true) {
    localStorage.removeItem(loggedIn)
    localStorage.removeItem(tokenExpiryKey)
    localStorage.removeItem('userData')
    localStorage.removeItem(jwtToken)
    
    store.state.user.loggedIn = false
    store.state.user.userData = null
    store.state.user.userData = null
    store.state.user.userEmailConfirmed = false
    if (deleteCart) {
      localStorage.removeItem('cart')
      store.dispatch('shoppingCart/emptyCart')
    }
  },

  setLoginParameters(token) {
    localStorage.setItem(loggedIn, 'true')
    localStorage.setItem(jwtToken, token)
    store.state.user.loggedIn = true
  },
  getDecodedJWT() {
    const jwt = localStorage.getItem(jwtToken)
    const decoded = jsonwebtoken.decode(jwt)

    return decoded
  },

  isAuthenticated() {
    const jwt = localStorage.getItem(jwtToken)
    const decoded = jsonwebtoken.decode(jwt)

    if (!jwt) {
      // user can still add items to cart even though he's not logged in
      // that's why remove all user data, except for cart
      // (when user logs out by clicking on button)
      const deleteCart = false
      this.logOut(deleteCart)
      return false
    }

    const stillValid = jwt
    && new Date(Date.now()) < new Date(decoded.validUntil)
    && localStorage.getItem(loggedIn) === 'true'

    if (!stillValid) {
      // if token expired, log user out completely and delete all data
      this.logOut()
    }

    return stillValid
  }

}

export default authService
