import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import Vue from 'vue'

const config = {
    onChange: ({changedCategories}) => {
        if (changedCategories.includes('analytics')) {
            if (CookieConsent.acceptedService('ga', 'analytics') || CookieConsent.acceptedCategory('analytics')) {
                Vue.prototype.$gtag.optIn()
            } else {
                Vue.prototype.$gtag.optOut()
            }
        } else if (changedCategories.includes('marketing')) {
            if (CookieConsent.acceptedService('facebook', 'marketing') || CookieConsent.acceptedCategory('marketing')) {

                const metaFix = document.scripts.namedItem('meta-fix')
                if (metaFix) return

                const script = document.createElement('script')
                script.setAttribute('name', 'meta-fix')
                script.innerHTML = `
                        !function(f,b,e,v,n,t,s)
                          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                              n.queue=[];t=b.createElement(e);t.async=!0;
                              t.src=v;s=b.getElementsByTagName(e)[0];
                              s.parentNode.insertBefore(t,s)}(window, document,'script',
                              'https://connect.facebook.net/en_US/fbevents.js');
                          fbq('init', '2902371510033127');
                          fbq('track', 'PageView');
                        `
                document.head.appendChild(script)
            } else {
                const metaFix = document.scripts.namedItem('meta-fix')
                if (metaFix) metaFix.remove()

                if (document.scripts.length > 0) {
                    for (let i = 0; i < document.scripts.length; i++) {
                        const src = document.scripts.item(i).getAttribute('src')
                        if (src && (src.includes('facebook') || src === 'https://connect.facebook.net/en_US/fbevents.js')) {
                            document.scripts.item(i).remove()
                        }
                    }
                }
            }
        }
    },
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom center'
        },
        preferencesModal: {
            layout: 'box'
        }
    },
    categories: {
        necessary: {
            readOnly: true,
            enabled: true
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/   // regex: match all cookies starting with '_ga'
                    },
                    {
                        name: '_gid'   // string: exact cookie name
                    }
                ]
            },
            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
            services: {
                ga: {
                    label: 'Google Analitika',
                    onAccept: () => {
                        Vue.prototype.$gtag.optIn()
                    },
                    onReject: () => {
                        Vue.prototype.$gtag.optOut()
                    }
                }
            }
        },
        marketing: {
            autoClear: {
                cookies: [
                    {
                        name: '_fbp'
                    }
                ]
            },
            services: {
                facebook: {
                    label: 'Facebook',
                    onAccept: () => {
                        const metaFix = document.scripts.namedItem('meta-fix')
                        if (metaFix) return

                        const script = document.createElement('script')
                        script.setAttribute('name', 'meta-fix')
                        script.innerHTML = `
                        !function(f,b,e,v,n,t,s)
                          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                              n.queue=[];t=b.createElement(e);t.async=!0;
                              t.src=v;s=b.getElementsByTagName(e)[0];
                              s.parentNode.insertBefore(t,s)}(window, document,'script',
                              'https://connect.facebook.net/en_US/fbevents.js');
                          fbq('init', '2902371510033127');
                          fbq('track', 'PageView');
                        `
                        document.head.appendChild(script)
                    },
                    onReject: () => {
                        const metaFix = document.scripts.namedItem('meta-fix')
                        if (metaFix) metaFix.remove()

                        if (document.scripts.length > 0) {
                            for (let i = 0; i < document.scripts.length; i++) {
                                const src = document.scripts.item(i).getAttribute('src')
                                if (src && (src.includes('facebook') || src === 'https://connect.facebook.net/en_US/fbevents.js')) {
                                    document.scripts.item(i).remove()
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    language: {
        default: 'sl',
        autoDetect: 'document',
        translations: {
            sl: {
                consentModal: {
                    title: 'Obvestilo o piškotkih',
                    description: 'Na spletni strani uporabljamo piškotke, da vam zagotovimo kar najboljšo uporabniško izkušnjo. Več v naši <a href="/piskotki">Politiki piškotkov</a> in <a href="/pogoji-varovanja-osebnih-podatkov">Informaciji o obdelavi osebnih podatkov</a>.',
                    acceptAllBtn: 'Sprejmi vse',
                    acceptNecessaryBtn: 'Zavrni vse',
                    showPreferencesBtn: 'Nastavitve piškotkov',
                    footer: ''
                },
                preferencesModal: {
                    title: 'Nastavitve piškotkov',
                    description: 'a',
                    acceptAllBtn: 'Sprejmi vse',
                    acceptNecessaryBtn: 'Zavrni vse',
                    savePreferencesBtn: 'Shrani nastavitve',
                    sections: [
                        {
                            description: 'Na spletni strani uporabljamo piškotke, da vam zagotovimo kar najboljšo uporabniško izkušnjo. Več v naši <a href="/piskotki">Politiki piškotkov</a> in <a href="/pogoji-varovanja-osebnih-podatkov">Informaciji o obdelavi osebnih podatkov</a>.'
                        },
                        {
                            title: 'Tehnični oz. funkcionalni piškotki',
                            description: 'Nekateri piškotki zagotavljajo, da nekateri deli spletnega mesta delujejo pravilno in da vaše uporabniške nastavitve ostajajo znane. Z namestitvijo funkcionalnih piškotkov vam olajšamo obisk našega spletnega mesta. Tako vam med obiskom našega spletnega mesta ni treba večkrat vnašati istih podatkov in izdelkov, ker na primer ostanejo v vaši košarici, dokler ne plačate. Te piškotke lahko uporabljamo brez vašega soglasja.',
                            linkedCategory: 'necessary',
                            cookieTable: {
                                caption: 'Seznam piškotkov',
                                headers: {
                                    name: 'Ime',
                                    description: 'Namen',
                                    duration: 'Poteče'
                                },
                                body: [
                                    {
                                        name: 'JWT',
                                        description: 'Uporablja se za prijavo na spletno mesto',
                                        duration: '400 dni (brez stalne prijave 9 ur)'
                                    },
                                    {
                                        name: 'JWT_IIPORTAL ',
                                        description: 'Uporablja se za avtentikacijo za pridobivanje podatkov iz iiPortala',
                                        duration: '400 dni (brez stalne prijave 9 ur)'
                                    },
                                    {
                                        name: 'cc_cookie',
                                        description: 'Shranjuje podatke o uporabljenih piškotkih',
                                        duration: 'pol leta'
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Analitični piškotki',
                            description: 'Za optimizacijo izkušnje spletnega mesta za naše uporabnike uporabljamo analitične piškotke. S temi analitičnimi piškotki upravljavci pridobijo vpogled v uporabo spletnega mesta. Prosimo za vaše dovoljenje (privolitev) za namestitev analitičnih piškotkov.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Seznam piškotkov',
                                headers: {
                                    name: 'Ime',
                                    description: 'Namen',
                                    duration: 'Poteče'
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        description: 'Piškotek  Google Analiteke omogoča, da storitev na spletni strani razlikuje enega obiskovalca od drugega.',
                                        duration: '6 mesecev od zadnje uporabe uporabnika'
                                    },
                                    {
                                        name: '_ga_25FY3KV2B2',
                                        description: 'Piškotek  Google Analiteke omogoča, da storitev na spletni strani razlikuje enega obiskovalca od drugega.',
                                        duration: '6 mesecev od zadnje uporabe uporabnika'
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Gumbi za socialna omrežja',
                            description: `Na našem spletnem mestu smo vključili gumbe za Facebook in Instagram za promocijo spletnih strani (npr. »Všeč mi je«, »pin«) ali skupno rabo (npr. »Tweet«) v družabnih omrežjih, kot sta Facebook in Instagram. Ti gumbi delujejo z uporabo kod, ki prihajajo s Facebooka in Instagrama. Ta koda postavlja piškotke. Ti gumbi za družbena omrežja lahko shranjujejo in obdelujejo tudi nekatere informacije, tako da se vam lahko prikaže prilagojen oglas. Preberite izjavo o zasebnosti teh družbenih omrežij (ki se lahko redno spreminjajo), za informiranje o tem, kaj počnejo z vašimi (osebnimi) podatki, ki jih obdelujejo s temi piškotki. Pridobljeni podatki so v največji možni meri anonimizirani. Facebook in Instagram se nahajata v ZDA. ZDA so država, ki ne zagotavlja enakega nivoja varstva osebnih podatkov kot Slovenija oziroma članice Evripske Unije in Evropskega gospodarskega prostora. Vaša privolitev v namestitev teh piškotkov (prek vtičnikov) šteje za tudi za izrecno privolitev za prenos vaših osebnih podatkov (s piškotki) navedenim upravljavcem v tretji državi v smislu točke a prvega odstavka 49. člena Splošne uredbe o varstvu podatkov.
                                <br><br>
                                FACEBOOK
                                <br>
                                Skupna raba podatkov: Za več informacij preberite <a href="https://www.facebook.com/policy/cookies" target="_blank">Facebookovo izjavo o zasebnosti</a>.
                                <br>
                                Piškotek se namesti na podlagi vaše privolitve.
                                `,
                            linkedCategory: 'marketing',
                            cookieTable: {
                                caption: 'Seznam piškotkov',
                                headers: {
                                    name: 'Ime',
                                    description: 'Namen',
                                    duration: 'Poteče',
                                    domain: 'Domena'
                                },
                                body: [
                                    {
                                        name: '_fbp',
                                        description: 'Shranjevanje in spremljanje obiskov na spletni strani.',
                                        duration: '3 mesece',
                                        domain: 'esoseska.si'
                                    }
                                ]
                            }
                        },
                        {
                            title: 'VPRAŠANJA',
                            description: 'Vsa vprašanja lahko naslovite na e-naslov: <a href="mailto:info@esoseska.si">info@esoseska.si</a>.'
                        }
                    ]
                }
            }
        }
    }
}

Vue.use(CookieConsent.run(config))