<template>
    <div
        id="app"
        class="height-print"
        :class="[skinClasses]"
    >
        <component :is="layout">
            <router-view />
        </component>
        <scroll-to-top v-if="enableScrollToTop" />
        <button class="change-cookies" type="button" data-cc="show-preferencesModal">
            <cookie-icon/>
        </button>
    </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
    import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
    import { provideToast } from 'vue-toastification/composition'
    import { watch } from '@vue/composition-api'
    import useAppConfig from '@core/app-config/useAppConfig'
    import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

    import { useWindowSize, useCssVar } from '@vueuse/core'

    import store from '@/store'
    import roundedButton from '@/views/Components/RoundedButton'
    import {BCard} from 'bootstrap-vue'
    import CookieIcon from '@/assets/CookieIcon.vue'

    const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
    const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
    const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

    export default {
        components: {

            // Layouts
            LayoutHorizontal,
            LayoutVertical,
            LayoutFull,
            ScrollToTop,
            roundedButton,
            BCard,
            CookieIcon
        },

        data() {
            return {
            }
        },
        methods: {
            async checkEmailConfirmation() {
                if (!this.loggedIn) {
                    return
                }

                try {
                    const res = await this.$http.get('/api/user/v1/check_email_confirmation')

                    if (res && res.data && res.data.email_confirmed === true) {
                        this.$store.dispatch('user/setEmailConfirmed', { confirmed: true})
                    } else {
                        this.$store.dispatch('user/setEmailConfirmed', { confirmed: false})
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake')
                }
            }
        },
        // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
        // Currently, router.currentRoute is not reactive and doesn't trigger any change
        computed: {
            loggedIn() {
                return this.$store.getters['user/loggedIn']
            },
            layout() {
                if (this.$route.meta.layout === 'full') return 'layout-full'
                return `layout-${this.contentLayoutType}`
            },
            contentLayoutType() {
                return this.$store.state.appConfig.layout.type
            }
        },

        beforeCreate() {
            // Set colors in theme
            const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = colors.length; i < len; i++) {
                $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
            }

            // Set Theme Breakpoints
            const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = breakpoints.length; i < len; i++) {
                $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
            }

            // Set RTL
            const { isRTL } = $themeConfig.layout
            document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
        },
        setup() {
            const { skin, skinClasses } = useAppConfig()

            // If skin is dark when initialized => Add class to body
            if (skin.value === 'dark') document.body.classList.add('dark-layout')

            // Provide toast for Composition API usage
            // This for those apps/components which uses composition API
            // Demos will still use Options API for ease
            provideToast({
                hideProgressBar: true,
                closeOnClick: false,
                closeButton: false,
                icon: false,
                timeout: 3000,
                transition: 'Vue-Toastification__fade'
            })

            // Set Window Width in store
            store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
            const { width: windowWidth } = useWindowSize()
            watch(windowWidth, val => {
                store.commit('app/UPDATE_WINDOW_WIDTH', val)
            })

            const { enableScrollToTop } = $themeConfig.layout

            return {
                skinClasses,
                enableScrollToTop
            }
        },
        async mounted() {
            await this.checkEmailConfirmation()

            // when application starts, sync user cart if user is logged in
            await this.$store.dispatch('shoppingCart/getUserShoppingCart')
            await this.$store.dispatch('unreadNotification/updateUnreadNotification')

        }
    }
</script>

<style scoped>

.height-print{
    height: 100vh !important;
}

.cookie-link {
    cursor: pointer;
    color: black
}

.cookie-link:hover {
    cursor: pointer;
    color: #92c5f1;
}


@media print {
    .height-print{
        height: initial !important;
    }
}

.change-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 12px;
  background: #72a5d8;
  color: white;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 4px 8px 0 rgba(0,0,0,0.19);
  z-index: 1000000000000;
}
</style>