import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faHandPointUp } from '@fortawesome/free-solid-svg-icons/faHandPointUp'
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard'
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons/faShoppingBag'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faThumbtack } from '@fortawesome/free-solid-svg-icons/faThumbtack'
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons/faArrowCircleDown'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import {faFile} from '@fortawesome/free-solid-svg-icons/faFile'
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import {faAngleUp} from '@fortawesome/free-solid-svg-icons/faAngleUp'
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import {faLock} from '@fortawesome/free-solid-svg-icons/faLock'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'

library.add(
    faPhoneAlt,
    faEnvelope,
    faCircle,
    faDownload,
    faCheckCircle,
    faVideo,
    faArrowRight,
    faPlusSquare,
    faSearch,
    faTimes,
    faPlus,
    faFilePdf,
    faHandPointUp,
    faTruck,
    faCreditCard,
    faShoppingBag,
    faUser,
    faQuestionCircle,
    faThumbtack,
    faCoins,
    faArrowCircleDown,
    faTag,
    faFile,
    faShoppingCart,
    faAngleUp,
    faBars,
    faPlusCircle,
    faLock,
    faNewspaper,
    faExclamationTriangle
)

// Register the component globally
Vue.component('fa', FontAwesomeIcon)
