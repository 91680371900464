const myProfileRoutes = [
    {
        path: '/my-profile/my-dashboard',
        name: 'my-dashboard',
        component: () => import('@/views/Pages/MyProfile/MyProfile.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Moj uporabniški račun',
                    to: '/my-profile/my-dashboard'
                },
                {
                    text: 'Moji uporabniški portal',
                    active: true
                }
            ],
            requiresAuth: true
        }
    },
    {
        path: '/my-profile/my-data',
        name: 'my-data',
        component: () => import('@/views/Pages/MyProfile/MyData.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Moj uporabniški račun',
                    to: '/my-profile/my-dashboard'
                },
                {
                    text: 'Moji uporabniški podatki',
                    active: true

                }
            ],
            requiresAuth: true
        }
    },
    {
        path: '/my-profile/my-coupons',
        name: 'my-coupons',
        component: () => import('@/views/Pages/MyProfile/MyCoupons.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Moj uporabniški račun',
                    to: '/my-profile/my-dashboard'
                },
                {
                    text: 'Moj kuponi',
                    active: true
                }
            ],
            requiresAuth: true
        }
    },
    {
        path: '/moj-profil/moja-narocila',
        name: 'my-orders',
        component: () => import('@/views/Pages/MyProfile/MyOrders.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Moj uporabniški račun',
                    to: '/my-profile/my-dashboard'
                },
                {
                    text: 'Moja naročila',
                    active: true
                }
            ],
            requiresAuth: true
        }
    },
    {
        path: '/moj-profil/moja-narocila/:order_id',
        name: 'my-order',
        component: () => import('@/views/Pages/MyProfile/Order.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Moj uporabniški račun',
                    to: '/my-profile/my-dashboard'
                },
                {
                    text: 'Moja naročila',
                    active: false,
                    to: '/moj-profil/moja-narocila'
                },
                {
                    text: 'Naročilo',
                    active: true
                }
            ],
            requiresAuth: true
        }
    }
]
export default myProfileRoutes