const realEstateRoutes = [
    {
        path: '/real-estate-list-out',
        name: 'real-estate-list-out'
    },

    {
        path: '/nepremicnine',
        name: 'real-estate-list',
        component: () => import('@/views/Pages/RealEstate/RealEstateList'),
        meta: {
            breadcrumb: [
                {
                    text: 'Nepremičnine',
                    active: true,
                    to: '/nepremicnine'
                }
            ],
            requiresAuth: false,
            listing_type: 0
        }
    },
    {
        path: '/nepremicnine/prodaja',
        name: 'real-estate-list-sale',
        component: () => import('@/views/Pages/RealEstate/RealEstateList'),
        meta: {
            breadcrumb: [
                {
                    text: 'Nepremičnine',
                    active: true,
                    to: '/nepremicnine'
                }
            ],
            requiresAuth: false,
            listing_type: 0
        }
    },
    {
        path: '/nepremicnine/oddaja',
        name: 'real-estate-list-rent',
        component: () => import('@/views/Pages/RealEstate/RealEstateList'),
        meta: {
            breadcrumb: [
                {
                    text: 'Nepremičnine',
                    active: false,
                    to: '/nepremicnine'
                }
            ],
            requiresAuth: true,
            listing_type: 1
        }
    },
    {
        path: '/nepremicnine/najem',
        name: 'real-estate-list-rental',
        component: () => import('@/views/Pages/RealEstate/RealEstateList'),
        meta: {
            breadcrumb: [
                {
                    text: 'Nepremičnine',
                    active: true,
                    to: '/nepremicnine'
                }
            ],
            requiresAuth: false,
            listing_type: 2
        }
    },
    {
        path: '/nepremicnine/zamenjava',
        name: 'real-estate-list-replacement',
        component: () => import('@/views/Pages/RealEstate/RealEstateList'),
        meta: {
            breadcrumb: [
                {
                    text: 'Nepremičnine',
                    active: true,
                    to: '/nepremicnine'
                }
            ],
            requiresAuth: false,
            listing_type: 3
        }
    }
]

export default realEstateRoutes