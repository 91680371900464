import axios from 'axios'
import store from '@/store'
import Vue from 'vue'


export default {
    namespaced: true,
    state: {
        unreadNotification: 0

    },
    getters: {
        unreadNotification(state) {
            return state.unreadNotification
        }
    },
    mutations: {
        UPDATE_UNREAD_NOTIFICATION(state, data) {
            state.unreadNotification = data
        }
    },
    actions: {
        async updateUnreadNotification({commit}) {
            try {
                if (!store.state.user.loggedIn) return
                const response = await axios.get('/api/iiportal-user/v1/e_manager/obvestila/unread_num')
                commit('UPDATE_UNREAD_NOTIFICATION', response.data.unread_num)
            } catch (error) {
                Vue.prototype.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
            }
        }
    }
}
