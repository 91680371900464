<template>
    <b-button v-if="!icon" :id="id" class="rounded-button" :style="`font-size: ${fontSize} !important; padding: ${padding} !important;`" @click="$emit('click', $event)"
              :disabled="disabled" :block="block">
        <slot></slot>
    </b-button>
    <b-button v-else-if="!iconLeft" :id="id" class="rounded-button d-inline-flex align-items-center" :style="`font-size: ${fontSize} !important; padding-top: ${padding.split(' ')[0]} !important; padding-bottom: ${padding.split(' ')[0]} !important; padding-left: ${padding.split(' ')[1]} !important; padding-right: 0 !important;`" @click="$emit('click', $event)"
              :disabled="disabled" :block="block">
        <span class="text-center w-100">
            <slot name="name"/>
        </span>
        <span :style="`padding-right: ${padding.split(' ')[paddingIcon]} !important;`">
            <slot class="" name="icon"/>
        </span>
    </b-button>
    <b-button v-else-if="iconLeft" :id="id" class="rounded-button d-inline-flex align-items-center" :style="`font-size: ${fontSize} !important; padding-top: ${padding.split(' ')[0]} !important; padding-bottom: ${padding.split(' ')[0]} !important; padding-right: ${padding.split(' ')[1]} !important; padding-left: 0 !important;`" @click="$emit('click', $event)"
              :disabled="disabled" :block="block">
        <span :style="`padding-left: ${padding.split(' ')[paddingIcon]} !important;`">
            <slot class="" name="icon"/>
        </span>
        <span class="text-center w-100">
            <slot name="name"/>
        </span>
    </b-button>
</template>
<script>
    import {BButton} from 'bootstrap-vue'
    export default {
        components: {BButton},
        props: {
            id: {
                type: String
            },
            fontSize: {
                type: String,
                default: '14px'
            },
            padding: {
                type: String,
                default: '0.9em 2em'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            block: {
                type: Boolean,
                default: false
            },
            icon: {
                type: Boolean,
                default: false
            },
            iconLeft: {
                type: Boolean,
                default: false
            },
            paddingIcon: {
                type: Number,
                default: 0
            }
        }
    }
</script>
<style scoped>
.rounded-button {
    border-color: none !important;
    background-color: #72A5D8;
    color: #ffffff !important;
    border-radius: 42px !important;

}
.rounded-button:hover, .rounded-button:active, .rounded-button:focus {
    box-shadow: none !important;
    background: #92c5f1 !important;
    border-color: #92c5f1 !important;
}
</style>