import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueGtm from '@gtm-support/vue2-gtm'
import VueGtag from 'vue-gtag'

import router from './router'
import store from './store'
import App from './App.vue'
import i18n from './i18n/i18n'
import dayjs from 'dayjs'
require('dayjs/locale/sl')
dayjs.locale('sl')

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/font-awesome'
import '@/libs/CookieConsentVue'

// axios
import axios from './axios.js'

// localize
import { localize } from 'vee-validate'
import authService from '@/auth/authService'
localize('sl')

// ADDS BEARER JWT TO EVERY REQUEST
axios.interceptors.request.use(
  config => {
    const jwt = localStorage.getItem('accessToken')
    if (jwt !== '') config.headers.common.Authorization = `Bearer ${jwt}`
    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => {
    if (response.data && response.data.confirmation_needed === true) {
      store.dispatch('user/setEmailConfirmed', { confirmed: false })
      router.push({ name: 'home'})
      return
    } 
    return Promise.resolve(response)
  },
  error => {
    if (error.response.status === 401) {
      router.push({ name: 'login' })
      authService.logOut()
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$NILObjectID = '000000000000000000000000'

Vue.prototype.$http = axios

Vue.prototype.dayjs = dayjs

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$printError = function(error) {
  this.$toast({
    component: ToastificationContent,
    props: {
      icon: 'DangerOctagonIcon',
      title: error,
      variant: 'danger'
    }
  })
}

Vue.prototype.$printWarning = function(message) {
  this.$toast({
    component: ToastificationContent,
    props: {
      icon: 'AlertTriangleIcon',
      title: message,
      variant: 'warning'
    }
  })
}

Vue.prototype.$printSuccess = function(message) {
  this.$toast({
    component: ToastificationContent,
    props: {
      icon: 'CheckCircleIcon',
      title: message,
      variant: 'success'
    }
  })
}

Vue.prototype.$scrollToTop = function() {
  const rootEle = document.getElementById('app')
  rootEle.scroll({
    top: 0,
    behavior: 'smooth'
  })
}

Vue.prototype.$scrollToElement = function(element) {
const breakpoint = this.$store.state.app.currentBreakPoint
if (breakpoint === 'sm' || breakpoint === 'md') { 
  element.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
} else element.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

Vue.prototype.$convertPriceIntegerToFloat = function(price) {
  if (!price) return (0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
  return (price / 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

Vue.prototype.$convertFloat = function(val) {
  return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtm, {
    id: 'GTM-5F2MD8L',
    enabled:true,
    vueRouter: router
  })

  Vue.use(VueGtag, {
    config: { id: 'G-25FY3KV2B2' },
    enabled: false
  }, router)
} else {
  Vue.use(VueGtag, {
    config: { id: 'G-XXXXXXXXXX' },
    enabled: false
  }, router)
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
