import axios from '@/axios'
import store from '@/store'
export default {
    namespaced: true,
    state: {
        cart: {
            items: [],
            total_price: 0,
            total_items: 0,
            shipping_price: 0
        },
        is_cart_sync_in_progress: false
    },
    getters: {
        items(state) {
            return state.cart.items
        },
        totalItems(state) {
            return state.cart.total_items || 0
        },
        totalPrice(state) {
            return state.cart.total_price || 0
        },
        shippingPrice(state) {
            return state.cart.shipping_price || 0
        },
        cart(state) {
            return state.cart
        }
    },
    mutations: {
        ADD_ITEM_TO_CART(state, data) {
            const item = data.item
            const quantity = data.quantity

            if (quantity < 1) return

            // whole item is saved in store (this includes price, title, description, image_url, all properties)
            const foundItemInCartIndex = state.cart.items.findIndex(i => i.id === item.id)

            // if item already in cart, increase quantity and update total_price
            if (foundItemInCartIndex !== -1) {
                state.cart.items[foundItemInCartIndex].quantity += quantity

                if (data.promotional_price) {
                    state.cart.items[foundItemInCartIndex].promotional_price = true
                    state.cart.items[foundItemInCartIndex].total_price = state.cart.items[foundItemInCartIndex].quantity * data.price_with_coupons
                } else {
                    state.cart.items[foundItemInCartIndex].total_price = state.cart.items[foundItemInCartIndex].quantity * item.price
                }
            } else {
                // otherwise set quantity to 1 and total_price to item price
                item.quantity = quantity

                if (data.promotional_price) {
                    item.promotional_price = true
                    item.total_price = data.price_with_coupons * item.quantity
                } else {
                    item.total_price = item.price * item.quantity
                }

                state.cart.items.push(item)
            }
        },
        UPDATE_CART_STATE(state) {
            if (!state.cart.items) state.cart.items = []

            const totalPrice = state.cart.items
                .map(item => {
                    // if cart is loaded from API, item has calculated total_price
                    if (item.total_price) return item.total_price

                    // TODO - is this necesary? I DONT FUCKING KNOW
                    if (item.discounted) return item.price_discounted * item.quantity
                    else return item.price * item.quantity
                })
                .reduce((prev, next) => { return prev + next }, 0)
            state.cart.total_price = isNaN(totalPrice) ? 0 : totalPrice

            const totalItems = state.cart.items
                .map(item => item.quantity)
                .reduce((prev, next) => { return prev + next }, 0)
            state.cart.total_items = isNaN(totalItems) ? 0 : totalItems


            if (state.cart.items.length === 0) {
                state.cart = {
                    items: [],
                    total_price: 0,
                    total_items: 0,
                    shipping_price: 0
                }
            }

            localStorage.setItem('cart', JSON.stringify(state.cart))
        },
        REMOVE_ITEM_FROM_CART_BY_INDEX(state, index) {
            state.cart.items.splice(index, 1)
        },
        REMOVE_ITEM_FROM_CART_BY_ITEM_ID(state, itemID) {
            
            const idx = state.cart.items.findIndex(item => item.id === itemID)
            if (idx === -1) {
                // TODO - ERROR
                return
            }
            state.cart.items.splice(idx, 1)
        },
        CHANGE_PROMOTION(state, data) {

            const item = data.item
            const quantity = data.quantity
            const itemID = data.id_to_remove

            if (quantity < 1) return

            // whole item is saved in store (this includes price, title, description, image_url, all properties)
            const foundItemInCartIndex = state.cart.items.findIndex(i => i.id === data.id)

            // if item already in cart, increase quantity and update total_price
            if (foundItemInCartIndex !== -1) {
                state.cart.items[foundItemInCartIndex].quantity += quantity

                if (data.promotional_price) {
                    state.cart.items[foundItemInCartIndex].promotional_price = true
                    state.cart.items[foundItemInCartIndex].total_price = state.cart.items[foundItemInCartIndex].quantity * data.price_with_coupons
                } else {
                    state.cart.items[foundItemInCartIndex].total_price = state.cart.items[foundItemInCartIndex].quantity * item.price
                }
            } else {
                // otherwise set quantity to 1 and total_price to item price
                item.quantity = quantity
                item.id = data.id

                if (data.promotional_price) {
                    item.promotional_price = true
                    item.total_price = data.price_with_coupons * item.quantity
                } else {
                    item.total_price = item.price * item.quantity
                }

                // state.cart.items.push(item)
            }

            const idx = state.cart.items.findIndex(item => item.id === itemID)
            if (idx === -1) {
                // TODO - ERROR
                return
            }
            state.cart.items.splice(idx, 1)
        },
        SET_CART(state, cart) {
            state.cart = cart
            
            if (!state.cart || typeof state.cart !== 'object') state.cart = {}
            if (!state.cart.items) state.cart.items = []
            if (state.cart.total_items === undefined || state.cart.total_items === null) state.cart.total_items = 0
            if (state.cart.total_price === undefined || state.cart.total_price === null) state.cart.total_price = 0
            if (state.cart.shipping_price === undefined || state.cart.shipping_price === null) state.cart.shipping_price = 0

            localStorage.setItem('cart', JSON.stringify(state.cart))
        },
        UPDATE_ITEM_QUANTITY(state, data) {
            const itemId = data.item_id
            const quantity = data.quantity

            if (!itemId) {
                // ERROR
                return
            }

            const idx = state.cart.items.findIndex(item => item.id === itemId)
            let idx2 = null

            if (itemId.includes('_coupons')) {
                idx2 = state.cart.items.findIndex(item => item.id === itemId.replace('_coupons', ''))
            } else {
                idx2 = state.cart.items.findIndex(item => item.id === `${itemId  }_coupons`)
            }

            if (idx2 !== -1) {
                const stock1 = state.cart.items[idx].quantity
                const stock2 = state.cart.items[idx2].quantity

                if (stock1 + stock2 >= data.max_stock) {
                    return
                }
            }

            if (idx === -1) {
                // ERROR
                return
            }

            if (quantity <= 0) state.cart.items.splice(idx, 1)
            else {
                state.cart.items[idx].quantity = quantity

                if (state.cart.items[idx].promotional_price) {
                    state.cart.items[idx].total_price = data.price_with_coupons * quantity
                } else {
                state.cart.items[idx].total_price = state.cart.items[idx].price * quantity
                }
            }
        },
        SET_ALL_ITEMS_PROPERTIES(state, items) {
            // whole item is saved in store (this includes price, title, description, image_url, all properties)
            items.map(item => {  
                const idx = state.cart.items.findIndex(i => i.id === item.id)
                if (idx !== -1) {
                    // merging together state.cart.items[idx], which contains total_price, quantity and id
                    // and item from API 
                    Object.assign(state.cart.items[idx], state.cart.items[idx], item)
                } else {
                    // TODO - ERROR
                }
            })

        },
        SET_SHIPPING_PRICE(state, shippingPrice) {
            state.cart.shipping_price = shippingPrice
        }
    },
    actions: {
        addItemToCart({ commit, dispatch}, data) {
            // receives whole item (from ES)
            // {
            //     item: { // object from ES
            //         ...
            //     },
            //     quantity: 1 // integer
            // }

            // if getUserShoppingCart fails for some reason, still try to add item to cart
            // .then() wouldn't execute if promise failed, but finally always will
            return dispatch('getUserShoppingCart').finally(() => {
                commit('ADD_ITEM_TO_CART', data)
                return dispatch('updateCartState')
            })
        },
        changePromotion({ commit, dispatch }, payload) {
            commit('CHANGE_PROMOTION', payload)
            dispatch('updateCartState')
        },
        removeItemFromCartByIndex({ commit, dispatch }, index) {
            commit('REMOVE_ITEM_FROM_CART_BY_INDEX', index)
            dispatch('updateCartState')
        },
        removeItemFromCartByItemID({ commit, dispatch }, itemID) {
            commit('REMOVE_ITEM_FROM_CART_BY_ITEM_ID', itemID)
            // by returning dispatch function, Promise is returned, which can then be awaited or used in .then() block.
            // if none of above is done, function get's executed anyway
            return dispatch('updateCartState')
        },
        setCart({ commit }, cart) {
            commit('SET_CART', cart)
            commit('UPDATE_CART_STATE')
        },
        updateItemQuantity({commit, dispatch}, data) {
            // receives data: { item_id: 'abc', quantity: 5}
            commit('UPDATE_ITEM_QUANTITY', data)
            dispatch('updateCartState')
        },
        updateCartState({commit, dispatch}) {
            commit('UPDATE_CART_STATE')
            // by returning dispatch function, Promise is returned, which can then be awaited or used in .then() block.
            // if none of above is done, function get's executed anyway
            return dispatch('syncUserShoppingCart')
        },
        setAllItemsProperties({commit}, items) {
            // receives array of items from ES and merges item from API with item in local storage
            commit('SET_ALL_ITEMS_PROPERTIES', items)
            commit('UPDATE_CART_STATE')
        },
        getUserShoppingCart({dispatch}) {
            if (!store.state.user.loggedIn) return
            // silent ignore, if error occurs
            return axios.get('/api/user/v1/web_shop/cart/')
            .then(res => {
                const cart = res.data
                dispatch('setCart', cart)
            })
        },
        syncUserShoppingCart({state}) {
            if (!store.state.user.loggedIn || state.is_cart_sync_in_progress) return

            state.is_cart_sync_in_progress = true

            const cart = {
                items: [],
                total_items: state.cart.total_items,
                total_price: state.cart.total_price
            }

            state.cart.items.map(item => {
                const itemPayload = {
                    id: item.id,
                    quantity: item.quantity,
                    promotional_price: item.promotional_price
                }
                cart.items.push(itemPayload)
            })

            // silent ignore if any error occurs 
            return axios.patch('/api/user/v1/web_shop/cart/sync', cart).finally(() => {
                state.is_cart_sync_in_progress = false
            })
        },
        async syncOnLogin({state}) {
            // if user doesn't have any tem in offline (localstorage) cart, try loading items from API
            if (state.cart.items.length === 0) {
                await store.dispatch('shoppingCart/getUserShoppingCart')
            } else {
                // otherwise overwrite synced cart with local cart
                await store.dispatch('shoppingCart/syncUserShoppingCart')
            }
        },
        emptyCart({state}) {
            state.cart.items = []
            state.cart.total_items = 0
            state.cart.total_price = 0
            state.cart.shipping_price = 0
        },
        setShippingPrice({commit}, shippingPrice) {
            if (!shippingPrice || typeof shippingPrice !== 'number') shippingPrice = 0
            commit('SET_SHIPPING_PRICE', shippingPrice)
        }
    }
}
